// colors
@av-gray: #F5F5F5;
@av-dark-black: #000000;
@av-red: #EF4046;
@av-white: #FFFFFF;
@av-blue: #2a9df4;

@form-input-bg-color: #F4F4F4;

@filter-header-background: #F9F9F9;
@filter-header-text: #939090;

//devices
@laptop: 1440;

.hand-cursor {
    cursor: pointer;
}

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    height:60px;
    width:60px;
    margin:0px auto;
    z-index: 100;
}