.app-logo {
    .ant-image-img {
        width: 100%;
    }
    width: 35px !important;
    height: 35px;

    @media (min-width: 1440px) {
        width: 45px !important;
        height: 45px;
    }
}

.nav {
    margin-top: 96px;
    margin-left: 5px;

    .nav-icon {
        width: 15px !important;
        height: 15px !important;
        margin-right: 6px;
    }
}

.activity-tab {
    &:hover {
        cursor: pointer;
    }
}