@import "navigation";
@import "modal";

// Activity Slider
#sider-logo {
    margin-top: 50px;
    margin-left: 12.5px;
}

.nav-activity {
    margin-left: 25px;
}

// Activity Content
#activity-content {
    margin-left: 120px;
    margin-top: 25px;
    margin-right: 35px;
}

.dropdown-menu {
    margin-top: 10px;
    margin-right: 10px;
    width: 160px;
    border-radius: 12px;
    font-weight: 600;
}

#notification-box {
    border-radius: 12px;
    width: 200px;
}

.activity-sider {
    height: 95vh;
    position: fixed;
    border-radius: 15px;
    background: @av-red;
    color: @av-white;
}

.confirm-modal {
    .custom-modal();

    .ant-modal-content {
        width: 400px;
        height: 200px;
        margin-left: 15%;
        
        @media (min-width: 1440px) {
            width: 450px;
        }
    }

    .confirm-modal-layout {
        align-items: center;
        text-align: center;
        padding-top: 5%;
    }
}

.goal-step-tag {
    color: #000000;
    
    padding: 0.2rem 0.5rem;
    border-radius: 16px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 5ch;

    @media (min-width: 800px) {
        max-width: 15ch;
    }

    @media (min-width: 1440px) {
        max-width: 25ch;
    }
}
@primary-color: #EF4046;@avcolor: #EF4046;