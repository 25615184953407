@import "modal";

.signup-modal {
    .custom-modal();

    .ant-modal-content {
        @media (min-width: 1440px) {
            width: 500px;
            height: 600px;
        }
    }
}

.signup-form {
    .modal-layout();
    margin-top: 20px;
}

.ant-tooltip-inner {
    border-radius: 10px;
}
@primary-color: #EF4046;@avcolor: #EF4046;