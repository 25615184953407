@import "../../global.less";


// reuse components
.wrapper {
    height: 650px;

    @media (min-width: 1440px) {
        height: 750px;
    }
}

// banner component
#banner-wrapper {
    height: 600px;

    @media (min-width: 1440px) {
        height: 900px;
    }
}

#banner-main {
    margin-left: 25%;
    margin-right: 25%;

    @media (min-width: 1440px) {
        margin-left: 31.5%;
        margin-right: 31.5%;
    }
}


// ussage component
#usage-box {
    min-height: 500px;
    align-items: center;
    justify-content: center;
    margin-left: 12.5%;
    margin-right: 12.5%;

    @media (min-width: 1440px) {
        min-height: 700px;
    } 
}

#usage-content {
    width: 95%;
    text-align: center;

    @media (min-width: 1440px) {
        padding-left: 7%;
        padding-right: 7%;
    }
}

.ant-layout {
    background-color: @av-white !important;
}

#usage-detail {
    margin-top: 50px;
    width: 100%;
}

.usage-item-detail {
    color: #444444;
    text-align: center;
    margin-left: 12.5%;
    margin-right: 12.5%;

    @media (min-width: 1440px) {
        margin-left: 25%;
        margin-right: 25%;
    }
}

#usage-box-desc {
    padding-left: 96px;
    padding-right: 96px;
    margin-bottom: 100px;
}

.usage-content-desc {
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50px;

    .usage-item-row {
        margin-top: 50px;
        flex-wrap: nowrap;
    }

    @media (min-width: 1440px) {
        margin-top: 96px;
        margin-bottom: 96px;
        margin-left: 96px;

        .usage-item-row {
            margin-top: 96px;
        }
    }
}

.usage-item-desc-1 {
    width: 85%;

    @media (min-width: 1440px) {
        width: 65%;
    }
}

.usage-item-desc-2 {
    width: 85%;

    @media (min-width: 1440px) {
        width: 70%;
    }
}


// feature component
.feature-box {
    max-height: 600px;
    padding-top: 85px;

    @media (min-width: 1440px) {
        padding-top: 100px;
    }
}

.feature-box-odd {
    background: @av-gray;
    padding-left: 10%;

    @media (min-width: 1440px) {
        padding-left: 15%;
    }
}

.feature-box-even {
    padding-bottom: 85px;
    padding-right: 5%;
    padding-left: 5%;

    @media (min-width: 1440px) {
        padding-left: 10%;
        padding-top: 100px;
    }
}

.feature-desc {
    padding-top: 48px;
    min-width: 250px;
    max-width: 320px;
    
    @media (min-width: 1440px) {
        min-width: 300px;
        max-width: 350px;
    }
}

.feature-desc-even {
    margin-left: -10%;
    
    @media (min-width: 1440px) {
        margin-left: 10%;
    }
}

.feature-desc-img {
    max-height: 600px;
    min-width: 300px;

    @media (min-width: 1440px) {
        min-width: 400px;
    }
}

.feature-desc-img-odd {
    margin-left: 10%;

    @media (min-width: 1440px) {
        margin-left: 20%;
    }    
}

.ant-image-img {
    @media (max-width: 1440px) {
        width: 80%;
    }
}


// pricing component
#pricing-wrapper {
    .wrapper();
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    min-width: 0;
    min-height: 0;
    padding: 96px;
}

.card {
    height: 360px;
    width: 280px;

    @media (min-width: 1440px) {
        height: 450px;
        width: 360px;
    }    
}

.card-header {
    height: 170px;
    justify-content: center;
    padding-left: 37px;
    color: @av-dark-black;
}

.card-body {
    box-sizing: border-box;
    height: 200px;
    padding: 30px 12px;
    color: #444444;
    background-color: @av-gray;
}

.card-footer {
    height: 90px;
    background: @av-gray;
    padding: 12px;
    color: @av-dark-black;
    justify-content: center;
}

.plan-detail {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color: #7E7E7E;
    letter-spacing: -0.025em;
}

.plan-button {
    width: 100px;
    height: 40px;
    margin-left: 24px;
    
    &:hover, &:focus, &:active {
        color: @av-dark-black;
    }

    @media (min-width: 1440px) {
        width: 120px;
        height: 45px;
    }    
}

// ourstory component
#ourstory-wrapper {
    .wrapper();
    background-color: #122133;
    padding: 96px;
    color: @av-white;
    display: flex;
}

#left-ourstory {
    padding-top: 150px;
    padding-left: 18px;

    @media (min-width: 1440px) {
        padding-top: 150px;
        padding-left: 24px;

        .ant-image, .ant-image-img {
            height: 65px;
            width: 106px;
        }

    }
}

#right-ourstory {
    padding-top: 5px;
    padding-left: 5%;


    @media (min-width: 1440px) {
        padding-top: 10px;
        padding-left: 10%;
    }
}

#red-plane {
    height: 250px;
    width: 200px;
    padding-left: 50px;

    @media (min-width: 1440px) {
        height: 300px;
        width: 350px;
        padding-left: 100px;
    }
}

#med-white-plane {
    height: 75px;
    width: 150px;
    padding-left: 15px;

    @media (min-width: 1440px) {
        height: 75px;
        width: 200px;
        padding-left: 25px;
    }
}

#main-ourstory {
    max-height: 450px;
    min-height: 300px;
    min-width: 300px;
    max-width: 450px;
    background-color: @av-white;
    margin-left: 2.5%;
    padding: 20px;
    color: @av-dark-black;

    @media (min-width: 1440px) {
        max-height: 600px;
        min-height: 500px;
        max-width: 800px;
        min-width: 650px;
        background-color: @av-white;
        margin-left: 10%;
        padding: 48px;
        color: @av-dark-black;
    }
}


// footer component
#footer {
    height: 850px;
}

#registration {
    height: 50%;
    background-color: @av-gray;
    align-items: center;
    justify-content: center;
}

#inner-registration {
    background-color: @av-red;
    padding: 96px;
    width: 85%;
    color: @av-white;
    box-sizing: border-box;
    height: 250px;
    display: flex;
    justify-content: flex-start;
}

#left-inner-registration{
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .ant-image, .ant-image-img {
        height: 80px;
        width: 80px;
    }
    
    #inner-text {
        padding-left: 48px;
    }
}



#right-inner-registration {
    width: 100%;
    align-items: flex-end;

    .ant-btn-lg {
        height: 50px;
        width: 164px;
    }

    #register-btn {
        border: solid 2px @av-white;
        background: transparent;
        outline: none;
        text-transform: none;
        color: @av-white;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
    }

    #inner-text {
        margin-top: 24px;
        line-height: normal;
    }
}




#contact {
    height: 50%;
    padding-top: 96px;
    box-sizing: border-box;

    #contact-box {
        justify-content: flex-start;
        margin-left: 14.5%;
        color: @av-dark-black;

        #top-contact {
            justify-content: center;
            align-items: center;

            .ant-image, .ant-image-img {
                height: 60px;
                width: 60px;
            }
        }

        #bottom-contact {
            padding-top: 48px;
        }
    }

    #media-box {
        box-sizing: border-box;
        align-items: flex-end;
        width: 100%;
        padding-top: 24px;
        padding-right: 17.5%;

        .ant-space-item {
            height: 21px;

            .ant-image, .ant-image-img {
                height: 100%;
                width: 100%;
            }
        }
    }
}
@primary-color: #EF4046;@avcolor: #EF4046;