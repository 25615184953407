@import "../../../global";
@import "modal";
@import "idea-modal";

// @idea-header-background: #F9F9F9;
// @idea-header-text: #939090;
@idea-select-button: #EAEAEA;
@idea-search-box-bg: #EEEEEE;


#idea-header {
    background-color: @filter-header-background;
    padding: 12px 0 12px 20px;
    border-radius: 15px;
    color: @filter-header-text;
    justify-content: space-between;
    padding-right: 15px;
}

.idea-select-button {
    background-color: @idea-select-button;
    border-radius: 8px;
    border: none;

    .idea-select-button-icon {
        padding-right: 7.5px;
        padding-top: 2.5px;
    }

    &:active, &:focus, &:hover {
        border: none;
        background-color: @idea-select-button;
        border-width: 0;
        color: @av-dark-black;
        border-color: @idea-select-button !important;
    }
}

.selector-content {
    background-color: @av-white;
    margin-top: 5px;
    width: 250px;
    height: 200px;
    padding: 0 10px;
    border-radius: 12px;
    overflow: hidden;

    .search-box {
        background-color: @idea-search-box-bg;
        margin-top: 15px;
        border-radius: 8px;
        color: #959595;

        #search-box-text {
            font-size: 12px;
            font-weight: 500;
        }
    }

    .selected-header-section {
        margin-top: 10px;
        justify-content: space-between;

        .selected-button-grp {
            color: @av-red;
        }
    }

    .checkbox-section {
        margin-top: 10px;
        margin-bottom: 25px;
        overflow-y: auto;

        .checkbox-group {
            display: flex;
            flex-direction: column;

            .ant-checkbox-wrapper {
                margin-left: 0px;
            }
        }
    }
}

.resize-icon {
    width: 80%;
    height: 80%;
}

.idea-option {
    padding-left: 5px;
}


// idea-modal
.idea-modal {
    .custom-modal();

    .ant-modal-body {
        padding: 0 0 !important;
    }

    .ant-modal-content {
        height: 480px;
        width: 700px;
        margin-left: -20%;
        @media (min-width: 1440px) {
            width: 800px;
        }
    }

    .idea-content {
        padding: 35px 30px 40px 40px;
        width: 100%;
        height: 420px;

        .idea-modal-title {
            align-items: center;
            gap: 8px;
        }

        .idea-modal-type {
            margin-top: 30px;
            height: 100%;

            // for image-idea
            .image-idea-item();

        }    
    }

    .idea-details {
        margin-top: 45px;
        width: 100%;

        .idea-name-input {
            .form-input();
            border-radius: 8px;
            padding: 8px 10px;
            color: #9C9C9C;
            font-size: 14px;
            width: 90%;
            line-height: 22px;
            letter-spacing: -0.025em;
            border: none;

            &:active, &:focus, &:hover {
                border: none;
                background-color: @form-input-bg-color;
                border-width: 0;
            }
        }

        .goal-selection, .step-selection {
            margin-top: 15px;
            width: 90%;
            font-size: 14px;
            font-weight: 600;

            .ant-select-selector, .ant-select {
                padding: 5px 10px;
                height: 40px;
                border-radius: 8px;
                border-color: #D4D4D4 !important;
                box-shadow: none !important;
                
                &:active, &:hover, &:focus {
                    border-color: #D4D4D4;
                    box-shadow: none;
                }
            }
        }
    }
}

.idea-column-layout {
    width: 10px;
    height: 20px;
    padding-right: 10px;
    background-color: #C4C4C4;
}

.idea-radio-button {
    width: 100px;
    border: 1px solid #E9E9E9;

    @media (min-width: 1440px) {
        width: 110px;
    }
}

.ant-radio-button-wrapper-checked {
    border: 1px solid #5667FF !important;
}

.idea-button {
    width: 95px;
    border-radius: 5px;

    @media (min-width: 1440px) {
        width: 110px;
    }
}

.file-icon-wrapper {
    align-self: center;
    height: 25%;
    width: 25%;
    margin-bottom: 12%;
}

// Pro con idea
.pro-con-idea-input {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    border: none;
    letter-spacing: -0.025em;
    line-height: 20px;
    padding: 10px 0;

    &:focus, &:active, &:hover {
        border: none;
        box-shadow: none;
    }
}

.pro-con-content-wrapper {
    width: 100%;
    text-align: left;
    
    .pro-con-content-item {
        width: 100%;
        justify-content: space-between;
    }
}

// Displayed idea item
.idea-view-list {
    margin-top: 1.5em;
}

.item-list {
    background-color: #F9F9F9;
    height: 100vh;
    margin-top: 10px;

    .idea-item {
        background-color: @av-white;
        height: 250px;
        width: 250px;
        margin-left: 15px;
        border-radius: 15px;
        box-shadow: 0px 6px 18px;
    }
}

@primary-color: #EF4046;@avcolor: #EF4046;