.image-idea-item {
    .ant-upload-drag {
        border: none;
        background: @form-input-bg-color  !important;
        padding: 0 0 !important;
    }

    .ant-upload {
        padding: 0 0 !important;
        border-radius: 10px;
    }

    .image-idea-display, 
    .video-idea-display,
    .soundbite-idea-display,
    .file-idea-display {
        height: 100%;
        justify-content: center;
    }
}