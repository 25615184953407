@import "activity";

.idea-edit-item {
    background-color: #FFFFFF;
    height: 80%;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.08);

    .idea-edit-item-inner {
        height: 100%;
        width: 100%;
        border-radius: 15px;
        padding: 0 0 !important;
        transition: 1s;

        .idea-edit-option-icon {
            padding-top: 2%;
            padding-right: 2%;

            .idea-edit-icon {
                height: 90%;
                width: 90%;
            }
        }

        .idea-edit-content {
            margin-top: 1%;
            padding-bottom: 1%;
            height: calc(70% - 7px);
        }

        .idea-edit-footer {
            height: 8%; 
            position: absolute;
            bottom: 2.5%;
            margin-left: 2%;
            margin-right: auto;
            background-color: #F6F6F6;
            padding: 7px 15px;
            width: 96%;
            border-radius: 8px
        }
    }
}

.idea-edit-nav {
    margin-top: 20%;
}

.item-edit-middle {
    border-radius: 15px;
}

.idea-goal-tag {
    .goal-step-tag();
    background-color: #FFD0D0;
}

.idea-step-tag {
    .goal-step-tag();
    background-color: #BBFBFF,
}
@primary-color: #EF4046;@avcolor: #EF4046;