@import '~antd/dist/antd.less';

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  box-sizing: border-box;

  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.025em;
  opacity: 1.0;
  min-width: 1024px;
  // @media (min-width: 1024px) {
  //   font-size: 16px;
  // }
  
}

@primary-color: #EF4046;@avcolor: #EF4046;