@import "modal";

.share-modal {
    .custom-modal();

    .ant-modal-header {
        border-bottom: none;
        border-radius: 16px;
    }

    .ant-modal-content {
        .ant-modal-body {
            padding-left:  35px;
            padding-right: 35px;

            .ant-col {
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
        }

        width: 550px;
        height: 460px;
        margin-left: 15%;
        @media (min-width: 1440px) {
            width: 600px;
        }
    }
}

.invitee-error-box {
    color: #EF4046;
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    align-items: center;
    height: 10px;
}

.invitee-list {
    padding-top: 0.5em;
    margin-top: 10px;
    height: 200px;
    overflow-y: auto;
}

.divider-gap {
    margin: 12px 0;
}

.remove-permission {
    color: red;
    background-color: transparent;

    &:active, &:focus, &:hover {
        color: red;
        border: none;
        background-color: transparent;
        border-width: 0;
    }
}
@primary-color: #EF4046;@avcolor: #EF4046;