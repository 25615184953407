@import "modal";

.bio-section {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    padding: 2% 2%;
    height: 220px;
    background-color: #FFF0F0;
    border-radius: 14px;
}

.profile-avatar {
    background-color: aqua;
}


.profile-avatar-section {
    height: 150px;
    background-color: #F4F4F4;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.profile-modal {
    .custom-modal();

    .ant-modal-header {
        border-bottom: none;
        border-radius: 16px;
    }

    .ant-modal-content {
        .ant-modal-body {
            padding-left:  40px;
            padding-right: 40px;
        }

        width: 450px;
        height: 600px;
        margin-left: 10%;

        @media (min-width: 1440px) {
            height: 600px;
        }
    }
}

.statistics-section {
    margin-bottom: 2%;
}

.activity-statistics {
    background-color: #F4F4F4;
    border-radius: 10px;
    height: 180px;
}

@primary-color: #EF4046;@avcolor: #EF4046;