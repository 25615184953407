@import "../../../global";

@media (min-width: 1700px) {
    .box-goal {
        height: 360px;
    }
    .wrapper-box-tasks {
        overflow-y: scroll;
        height: 500px;
    }
}
@media (min-width: 2049px) {
    .box-goal {
        height: 500px;
    }
    .wrapper-box-tasks {
        overflow-y: scroll;
        height: 700px;
    }
}
@media (min-width: 2732px) {
    .box-goal {
        height: 600px;
    }
    .wrapper-box-tasks {
        overflow-y: scroll;
        height: 900px;
    }
}
@media (min-width: 4098px) {
    .box-goal {
        height: 800px;
    }
    .wrapper-box-tasks {
        overflow-y: scroll;
        height: 1500px;
    }
}
@media (max-width: 1699px) {
    .box-goal {
        height: 300px;
    }
    .wrapper-box-tasks {
        overflow-y: scroll;
        height: 500px;
    }
}

.shared-users-avatar {
    float: right;
    margin-top: 2%;
    margin-right: 2%;
    color: white;
}

// Goal detail - ideas
.box-idea-content {
    height: 250px;
    width: 100%;
    border-radius: 15px;
    padding: 0 0 !important;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.08);

    .box-idea-content-body {
        height: calc(70% - 7px);
    }

    .box-idea-content-footer {
        height: 30%; 
        position: absolute;
        bottom: 5%;
        margin-left: 2%;
        margin-right: auto;
        background-color: #F6F6F6;
        padding: 7px 15px;
        width: 96%;
        border-radius: 8px
    }
}


// Goal detail - tasks
#goal-detail-header {
    padding-top: 35px;
    justify-content: space-between;
    
    .resize-icon {
        width: 80%;
        height: 80%;
    }
}

.wrapper-react-multi-carousel-list {
    margin-top: 30px;
    background-color: #f9f9f9;
    border-radius: 16px;
    padding: 25px;
}

.box-content {
    background-color: #f9f9f9;
    border-radius: 16px;
    padding: 20px;
    height: 100%;
}

.box-task-content {
    background-color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 15px;
}
@primary-color: #EF4046;@avcolor: #EF4046;