@import "modal";

.login-modal {
    .custom-modal();

    .ant-modal-content {
        width: 400px;
        height: 550px;
        margin-left: 15%;
        @media (min-width: 1440px) {
            width: 450px;
            height: 600px;
        }
    }
}

.login-form {
    .modal-layout();
    margin-top: 55px;
}

.login-password-item {
    width: 500px;

    #password-label {
        display: inline;
    }
    
    #forget-password {
        float: right;
        text-decoration: underline;
        color: @av-blue;
        border: none;
        background: none;
    }
}
@primary-color: #EF4046;@avcolor: #EF4046;