@import "modal";

.create-modal {
    .custom-modal();

    .ant-modal-header {
        border-bottom: none;
        border-radius: 16px;
    }

    .ant-modal-content {
        .ant-modal-body {
            padding-left:  40px;
            padding-right: 40px;
        }

        width: 450px;
        height: 550px;
        margin-left: 10%;

        @media (min-width: 1440px) {
            height: 590px;
        }
    }
}

.form-input-v2 {
    .form-input();
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #9C9C9C;
    letter-spacing: -0.025em;
    line-height: 18px;
    border: none;

    &:active, &:focus, &:hover {
        border: none;
        background-color: @form-input-bg-color;
        border-width: 0;
    }
}

.ant-picker {
    background-color: @form-input-bg-color;
    border: none;

    &:active, &:focus, &:hover {
        border: none;
        background-color: @form-input-bg-color;
        border-width: 0;
    }
}

.form-date-picker {
    .form-input();
    width: 100%;
    border-radius: 6px;

    .ant-picker-input > input {
        text-align: right;
        padding-right: 5px;
        padding-left: 10px;
        order: 2;
    
        .ant-picker-suffix 
        {
            order: 1;
        }
    
        &::placeholder{
            text-align:left;
        }
    }
}

.file-upload-item {
    .ant-upload {
        padding: 0 0 !important;
    }

    .ant-upload-drag {
        border: none;
        background: @form-input-bg-color  !important;
        border-radius: 5px;
    }

    .file-display {
        height: 120px;
        justify-content: center;

        @media (min-width: 1440px) {
            height: 150px;
        }
    }
}

@primary-color: #EF4046;@avcolor: #EF4046;