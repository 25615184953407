@import "../../../global";
@import "modal";

.relfection-modal {
    .custom-modal();
}

.reflection-banner-wrapper {
    border-radius: 15px;
    height: 350px;
    color: @av-white;
    position: relative;
    margin-top: 3%;
}

.reflection-banner-top {
    padding: 2% 2%;
    gap: 10px;
}

.change-cover-img {
    margin-top: 0.35em;
}

.reflection-icon-option {
    width: 24px;
    margin-top: 0.5em;
}

.reflection-modal {
    .custom-modal();

    .ant-modal-header {
        border-bottom: none;
        border-radius: 16px;
    }

    .ant-modal-content {
        .ant-modal-body {
            padding-left:  40px;
            padding-right: 40px;
        }

        width: 550px;
        height: 450px;
        margin-left: 10%;

        @media (min-width: 1440px) {
            height: 500px;
        }
    }
}

.reflection-radio-button {
    width: 160px;
    border: 1px solid #E9E9E9;

    @media (min-width: 1440px) {
        width: 180px;
    }
}

.reflection-column-layout {
    width: 10px;
    height: 20px;
    padding-right: 10px;
    background-color: #C4C4C4;
}

.reflection-button {
    width: 120px;
    border-radius: 5px;

    @media (min-width: 1440px) {
        width: 140px;
    }
}

.reflection-file-display {
    height: 130px;
    justify-content: center;

    @media (min-width: 1440px) {
        height: 150px;
    }
}

.reflectionColorSelect {
    &:hover {
        font-size: 100px;
    }
}


// Display reflections
.reflection-view-list {
    margin-top: 3.5em;
}

.box-reflection {
    height: 250px;
    width: 100%;
    border-radius: 10px;
    padding: 1em 1em;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;

    .box-reflection-footer {
        position: absolute; 
        width: 100%;
      
        right: 0;
        bottom: 0.5em;
        justify-content: space-between;
    }

    .text-reflection {
        padding: 0 1.5em;
    }

    .visual-reflection {
        padding: 0 1em;
    }
}

@primary-color: #EF4046;@avcolor: #EF4046;