@media (min-width: 1500px) {
    .btn-new-in-tabs {
        margin-right: 0.75rem !important;
    }
}
@media (min-width: 1700px) {
    .box-step {
        height: 360px;
    }
    .btn-new-in-tabs {
        margin-right: 1rem !important;
    }
}
@media (min-width: 2049px) {
    .box-step  {
        height: 500px;
    }
}
@media (min-width: 2732px) {
    .box-step  {
        height: 600px;
    }
    .btn-new-in-tabs {
        margin-right: 1.5rem !important;
    }
}
@media (min-width: 4098px) {
    .box-step  {
        height: 800px;
    }
    .btn-new-in-tabs {
        margin-right: 2.5rem !important;
    }
}
@media (max-width: 1699px) {
    .box-step  {
        height: 300px;
    }
}


#step-detail-header {
    padding-top: 25px;
    justify-content: space-between;
    padding-bottom: 25px;
    
    .resize-icon {
        width: 80%;
        height: 80%;
    }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 35px;
  z-index: 10;
  color: #EDEDED;
}

.ant-carousel .slick-prev {
    left: -20px;
}

.ant-carousel .slick-next {
    right: 0px;
}


.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
    color: #EDEDED;
}
.ant-tabs-tab {
    color: #B9B9B9;
    padding-left: 5px;
    padding-right: 5px;
}
.ant-tabs-tab-active {
    span {
        color: #000000 !important;
    }
}
.ant-tabs-nav-list {
    padding-left: 15px;
}

.goal-step-text > span {
    font-size: 6px;

    @media only screen and (min-width: 575px) {
        font-size: 7px
    }

    @media only screen and (min-width: 768px) {
        font-size: 8px
    }

    @media only screen and (min-width: 1024px) {
        font-size: 10px
    }

    @media only screen and (min-width: 1440px) {
        font-size: 12px
    }
}
@primary-color: #EF4046;@avcolor: #EF4046;