@import "navigation";

#left-homepage {
    margin-top: 35px;
    margin-left: 35px;

    @media (min-width: 1440px) {
        margin-top: 60px;
        margin-left: 60px;
    }
    .app-logo();
}

#right-homepage {
    align-items: center;
    margin-top: 150px;
    width: 80%;

    #timer {
        align-items: center;
    }

    #greeting {
        padding-top: 90px;
        align-items: center;
    }

    #pointer-icon {
        padding-top: 96px;
        padding-bottom: 15px;

    }

    #quote {
        align-items: center;
        padding-top: 70px;
    }

    @media (min-width: 1440px) {
        margin-top: 150px;

        #greeting {
            padding-top: 100px;
        }

        #pointer-icon {
            padding-top: 100px;
            padding-bottom: 25px;
        }

        #quote {
            padding-top: 10%;
            padding-bottom: 1%;
        }
    }
}

.home-nav-sider {
    z-index: 9999;
}
@primary-color: #EF4046;@avcolor: #EF4046;