@import "../../../global";
@import "modal";
@import "activity";

#todo-header {
    background-color: @filter-header-background;
    padding: 0 0 0 20px;
    border-radius: 15px;
    color: @filter-header-text;
    justify-content: space-between;
    padding-right: 15px;
}

#todo-sub-header {
    padding: 20px 0;
    justify-content: space-between;
}

.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0px;
}

.ant-tabs-tab {
    padding: 20px 0;
}

.date-picker {
    border: 1px solid #EDEDED;
    border-radius: 8px;
    padding: 2px 5px;

    .date-nav {
        border: none;
        box-shadow: none;
        outline: none;

        &:hover, &:focus, &:active {
            border: none;
            outline: none;
            color: black;
            box-shadow: none;
        }
    }
}

// todo-calendar.jsx
.calendar-item {
    width: 13.5%;
    border-radius: 10px;
    padding: 7px 10px;
}

.weekday {
    background-color: #FBF9F9;
}

.weekend {
    background-color: #FFE9E9;
}

.not-within-month {
    color: #B8B8B8;
}

.monthly-view {
    height: 125px;

    @media only screen and (min-width: 1024px) {
        height: 150px;
    }

    @media only screen and (min-width: 1440px) {
        height: 175px;
    }
}

.weekly-view {
    height: 250px;

    @media only screen and (min-width: 1024px) {
        height: 350px;
    }

    @media only screen and (min-width: 1440px) {
        height: 400px;
    }
}

.task-box {
    position: absolute;
    width: 90%;
    gap: 2px;

    .task-item {
        background-color: #FFFFFF;
        border-radius: 6px;
        width: 100%;
        padding: 2px 8px;
        margin-bottom: 5px;
        line-height: 0.5rem;
    }    

    .task-item-more {
        align-items: center;

        .task-item-more-txt {
            background-color: #FFFFFF;
            border-radius: 6px;
            padding: 5px 16px;
        }
    }
}

.monthly-task-view {
    bottom: 4%;
}

.weekly-task-view {
    margin: 0;
    top: 30%;
}

.task-modal {
    .custom-modal();

    .ant-modal-header {
        border-bottom: none;
        border-radius: 16px;
    }

    .ant-modal-content {
        .ant-modal-body {
            padding-left:  40px;
            padding-right: 40px;
        }

        width: 450px;
        height: 480px;
        margin-left: 10%;

        @media (min-width: 1440px) {
            height: 500px;
        }
    }
}

.form-input-task {
    padding: 10px;
}

.task-gs-selection {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0;

    .ant-select-selector {
        border-radius: 8px !important;
        border-color: #D4D4D4 !important;
        box-shadow: none !important;
        
        &:active, &:hover, &:focus {
            border-color: #D4D4D4 !important;
            box-shadow: none !important;
        }
    }
}

.task-button {
    width: 95px;
    border-radius: 5px;

    @media (min-width: 1440px) {
        width: 110px;
    }
}

// todo-detail.jsx
.todo-detail {
    background-color: #FBF9F9;
    width: 95%;
    height: 450px;
    border-radius: 16px;
    padding: 0 5%;
    overflow-y: auto;
    margin-top: 5%;
    
    @media only screen and (min-width: 1024px) {
        height: 550px;
    }

    @media only screen and (min-width: 1440px) {
        height: 650px;
    }
}

.new-task-btn {
    background-color: #EDEDED;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    border: none;
    box-shadow: none !important;
    outline: none;

    &:active, &:focus, &:hover {
        color: #000000;
        background-color: #EDEDED;
        border: none;
        border-color: #EDEDED !important;
    }  
}

.todo-detail-item {
    padding: 5% 0px 5% 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 5%;
}

.task-option {
    margin-top: 2px;
    margin-right: 2px;
    width: 80px;
    border-radius: 8px;
    
    .ant-dropdown-menu-item {
        padding: 2px 12px;
    }
}

.task-goal-tag {
    .goal-step-tag();
    background-color: #FFD0D0;
    max-width: 5ch;
    border-radius: 8px;

    @media (min-width: 800px) {
        max-width: 15ch;
    }
}

.task-step-tag {
    .goal-step-tag();
    background-color: #BBFBFF;
    max-width: 5ch;
    border-radius: 8px;

    @media (min-width: 800px) {
        max-width: 15ch;
    }
}

.task-complete {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    color: #B3B3B3
}

.task-description-display {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #868585;
    width: 90%;
    overflow: hidden;
    line-height: 1em;
    max-height: 3.6em;
    text-overflow: ellipsis;
}

.today-highlight {
    border: 1.5px solid red;
}

.today-button {
    border: 1px solid #EF4046;
    color: #FFFFFF;
    background-color: #EF4046;
    border-radius: 5px;
    width: 100px;

    &:hover, &:focus, &:active {
        box-shadow: none;
        border: 1px solid #EF4046;
        color: #FFFFFF;
        background-color: #EF4046;
    }
}
@primary-color: #EF4046;@avcolor: #EF4046;