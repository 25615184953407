@import "modal";

.password-modal {
    .custom-modal();

    .ant-modal-content {
        width: 400px;
        height: 330px;
        margin-left: 15%;
        
        @media (min-width: 1024px) {
            width: 450px;
            height: 310px;
        }

        @media (min-width: 1440px) {
            width: 450px;
            height: 320px;
        }
    }
}

.password-info-modal {
    .custom-modal();

    .ant-modal-content {
        width: 400px;
        height: 200px;
        margin-left: 15%;
        
        @media (min-width: 1440px) {
            width: 450px;
        }
    }
}

.password-help-modal {
    .custom-modal();

    .ant-modal-content {
        width: 400px;
        height: 370px;
        margin-left: 15%;

        @media (min-width: 768px) {
            height: 330px;
        }
        
        @media (min-width: 1440px) {
            width: 450px;
            height: 340px;
        }
    }
}

.password-reset-modal {
    .custom-modal();

    .ant-modal-content {
        width: 400px;
        height: 450px;
        margin-left: 15%;
        @media (min-width: 1440px) {
            width: 400px;
        }
    }
}

.password-help-layout {
    align-items: center;
    text-align: center;

    .password-help-content {
        width: 80%;
        padding-top: 5%;
    }

    .ok-button {
        background-color: @av-red;
        border-color: @av-red;
        color: @av-white;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.025em;
        height: 40px;
        border-radius: 10px;
    
        &:hover, &:focus, &:active {
            color: @av-white;
        }
    }
}

.reset-password-form {
    .modal-layout();
    margin-top: 20px;

    .password-error {
        height: 10px;

        @media (min-width: 1440px) {
            height: 35px;
        }

    }
}

.password-reset-email > .ant-form-item-label >  label {
    height: 100%;
}
@primary-color: #EF4046;@avcolor: #EF4046;