.idea-content-box {
    padding-left: 3%;
    padding-top: 3%;
}

.note-idea {
    height: 90%;
    width: 97%;
    overflow-y: hidden;
    white-space: nowrap;
    text-align: left;
}


.pro-con-idea  {
    font-size: 6px;
    line-height: 0;

    @media only screen and (min-width: 575px) {
        font-size: 7px
    }

    @media only screen and (min-width: 768px) {
        font-size: 8px
    }

    @media only screen and (min-width: 992px) {
        font-size: 8px;
        
    }

    @media only screen and (min-width: 1024px) {
        font-size: 12px;
        line-height: 15px;
    }

    @media only screen and (min-width: 1440px) {
        font-size: 14px;
        line-height: 18px;
    }
}

.pro-mark, .con-mark {
    width: 10px;
    height: 10px;
}
@primary-color: #EF4046;@avcolor: #EF4046;